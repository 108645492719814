<script>
  export default {
    props: {
      radiusCard: {
        type: Number,
        default: 9,
      },
    },
  };
</script>

<template>
  <article class="mainCard" :style="{ 'border-radius': `${radiusCard}px` }">
    <slot class="mainCard__wrapper" name="wrapper"></slot>
    <slot class=""></slot>
  </article>
</template>

<style lang="scss">
  .mainCard {
    @include Flex(column, flex-start, flex-start);
    width: 100%;
    gap: 15px;
    padding: 15px;
    background: #fff;
    box-shadow: 0px 1px 4px 0px #00000040;
  }
</style>
